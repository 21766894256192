
import React, { Component } from 'react';

function ModulesSection1() {
    return (
        <section className="section-wrap bottom-divider pb-100 ">
            <div className="container">
                <div className="page-title__outer">
                    <div className="row">
                        <div className='col-12 mt-5'></div>
                        <div className="col-12 col-md-8 offset-md-2 mt-5" data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500">
                            <h1 className="page-title__title mb-5">Módulos</h1>
                            <p className=" lead fs-4 c-8b95a3 text-center">Nuestra plataforma cuenta con todas las herramientas necesarias para llevar a cabo un proyecto exitoso.</p>
                            <p className='fs-6 c-8b95a3 text-center'>Inspirum permite que el cliente pueda administrar los proyectos de forma fácil y segura desde una consola de Administración.
                                Por otro lado, a los usuarios les permite dar seguimiento a sus avances, capacitarse, estar en comunicación constante con el administrador y redimir sus beneficios obtenidos de forma segura, cómoda y amigable.</p>
                        </div>
                        <div className='col-12 mt-5'></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection1;