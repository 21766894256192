
import React, { Component } from 'react';
import img_3 from '../../Assets/Img/home_img_3.jpeg';
import IcoSvg from '../shared/svg/icoSvg';
function HomeSection4() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6" ata-aos="fade-left">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Multiplataforma</h2>

                            <ul className="estio-lista c-8b95a3">
                                <li><IcoSvg /> Web App</li>
                                <li><IcoSvg /> App iOS</li>
                                <li><IcoSvg /> App Android</li>
                                <li><IcoSvg /> + Consola de Administración</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 offset-lg-1" data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="300"
                        data-aos-offset="0">
                        <img src={img_3} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection4;