
import React, { Component } from 'react';
import img_2 from '../../Assets/Img/modules_img_2.jpeg';
function ModulesSection3() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="fade-right" >
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Registro de Usuarios</h2>
                            <p className="lead c-8b95a3 text-wrap">Cada usuario cuenta con un perfil personal. Los usuarios se pueden registrar en los planes activos a través de la App o desde la web.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img className="img-fluid" src={img_2} alt="" />

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection3;