
import React, { Component } from 'react';
import img_2 from '../../Assets/Img/home_img_2.png';
import IcoSvg from '../shared/svg/icoSvg';
function HomeSection3() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img src={img_2} alt="" className="img-fluid " />
                    </div>
                    <div className="col-10 col-md-5 offset-1 offset-md-1" data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Principales Módulos</h2>

                            <ul className="estio-lista c-8b95a3">
                                <li><IcoSvg /> Cálculo de puntos</li>
                                <li><IcoSvg /> Comunicación</li>
                                <li><IcoSvg /> Capacitación</li>
                                <li><IcoSvg /> LeaderBoards</li>
                                <li><IcoSvg /> Administración de Información</li>
                                <li><IcoSvg /> Redenciones digitales</li>
                                <li><IcoSvg /> Recompensas Emocionales</li>
                                <li><IcoSvg /> Reporteo</li>
                            </ul>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default HomeSection3;