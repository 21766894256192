import React, { Component } from 'react';
import IcoSvgIn from '../shared/svg/icoSvgIn';
import logo from '../../Assets/Img/logo.png';
function Footer() {
    return (
        <footer className="footer">
            <div className="container">

                <div className="row pb-100 pt-100 bottom-divide">
                    <div className="col-12 col-md-4 text-sm-center text-lg-start mt-5">
                        <img className="logo-img mb-4" src={logo} alt="" />
                        <br></br>
                        <a className="socials--nobase social-instagram mt-4" href="https://www.linkedin.com/company/inspirum-mexico" title="Linkedin" target="_blank" rel="noopener nofollow">
                            <IcoSvgIn />
                        </a>
                    </div>
                    <div className='col-0 col-md-6'></div>
                    <div className="col-12 col-md-2 mt-5">
                        <div className="widget widget-links">
                            <h5 className="widget-title">Teléfono</h5>
                            <p className='c-8b95a3'>67255066</p>
                            <h5 className="widget-title">Empresa</h5>
                            <a target='_blank' href="https://inspirum.blob.core.windows.net/legal/Bizlab_Aviso_de_Privacidad.pdf" className='c-8b95a3'><i className="ui-document"></i> Aviso de privaciad</a>
                        </div>
                    </div>


                </div>
            </div>

            <div className="footer__bottom top-divider">
                <div className="container text-center">
                    <span className="copyright c-8b95a3 fs-6">
                        2020 Inspirum
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
