
import React, { Component } from 'react';

function HomeSection2() {
    return (

        <section className="section-wrap bottom-divider">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-5 mb-5" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine">
                        <span className="intro__subtitle fs-6 text-uppercase">Incentiva a tus empleados y alinea sus intereses con los objetivos de tu compañía.</span><p></p>
                        <p className="intro__title c-3e4045">
                            Nuestros servicios permiten a los clientes tomar mejores decisiones estratégicas, optimizar el comportamiento de los empleados y optimizar la efectividad operativa a través de módulos para monitoreo de objetivos parametrizables y módulos de comunicación.
                        </p>
                        <p></p>
                    </div>
                    <p></p></div>
                <p></p></div>
        </section >

    )
}

export default HomeSection2;