import React, { useState, useEffect } from 'react';
import Menu from './shared/menu';
import HomeSection1 from './homeSection/section1';
import HomeSection2 from './homeSection/section2';
import HomeSection3 from './homeSection/section3';
import HomeSection4 from './homeSection/section4';
import HomeSection5 from './homeSection/section5';
import '../Assets/Css/general.css';
import Footer from './shared/footer';
import Loader from './shared/loader';
function Home() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simula un tiempo de carga de 2 segundos
        setTimeout(() => {
            setIsLoading(false);
        }, 900);
    }, []);
    return (
        <div >
            {isLoading ? (
                <Loader></Loader>
            ) : (
                <div><Menu></Menu>
                    <HomeSection1></HomeSection1>
                    <HomeSection2></HomeSection2>
                    <HomeSection3></HomeSection3>
                    <HomeSection4></HomeSection4>
                    <HomeSection5></HomeSection5>
                    <Footer></Footer></div>
            )}


        </div >
    )
}

export default Home;