
import React, { Component } from 'react';
import img_5 from '../../Assets/Img/modules_img_5.jpeg';
function ModulesSection6() {
    return (
        <section class="section-wrap promo-section bottom-divider pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 mb-5" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img class="img-fluid" src={img_5} alt="" />

                    </div>
                    <div class="col-12 col-md-6" data-aos="fade-left">
                        <div class="promo-section__description">
                            <h2 class="promo-section__title">Foro de Discusión</h2>
                            <p class="lead c-8b95a3 text-wrap">En este los usuarios pueden publicar preguntas que pueden ser respondidas y votadas por parte de la administración u otros usuarios apoyando de esta forma la comunicación interna y la integración de la comunidad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection6;