import React from 'react';

function icoSvg() {
    return (
        <svg viewBox="0 0 256 256" width="18px" height="18px" fillRule="nonzero">
            <g fill="#8b95a3" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
                stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                font-size="none" text-anchor="none" >
                <g transform="scale(4,4)">
                    <path d="M27,55l-21,-22l3,-4l17,12l29,-29l4,4z"></path>
                </g>
            </g>
        </svg>
    )
}

export default icoSvg;
