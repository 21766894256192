
import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [body, setBody] = useState('');
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [bodyError, setBodyError] = useState(false);
    const [privacyCheckedError, setPrivacyCheckedError] = useState(false);
    const enviarCorreo = (e) => {
        e.preventDefault();

        // Obtén los valores de los campos del formulario
        const data = {
            name: name,
            mail: mail,
            message: body
        };
        const headers = {
            'Authorization': 'Bearer 90098c7ba04e313961ecb5459dab0b2f',
            'Content-Type': 'application/json'
        };
        if (name.trim() === '') {
            setNameError(true);
        }
        else {
            setNameError(false);
        }
        if (mail.trim() === '') {
            setMailError(true);
        }
        else {
            setMailError(false);
        }
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(mail)) {
            setMailError(true);
        } else {
            setMailError(false);
        }
        if (body.trim() === '') {
            setBodyError(true);
        }
        else {
            setBodyError(false);
        }
        if (!privacyChecked) {
            setPrivacyCheckedError(true);
        } else {
            setPrivacyCheckedError(false);
        }

        if (name.trim() === '' || mail.trim() === '' || body.trim() === '' || !emailRegex.test(mail) || !privacyChecked) {
            return;
        }

        axios.post('phpserver/mailSend.php', data, { headers })
            .then(response => {
                // Maneja la respuesta exitosa
                if (response.data.success === 0) {
                    limpiarFormulario();
                    alert(response.data.message);
                } else {
                    alert(response.data.message + ' - ' + response.data.success);
                }

            })
            .catch(error => {
                // Maneja el error
                console.error(error);
            });
    }
    const limpiarFormulario = () => {
        setName('');
        setMail('');
        setBody('');
        setPrivacyChecked(false);
        setNameError(false);
        setMailError(false);
        setBodyError(false);
        setPrivacyCheckedError(false);
    }
    const handlePrivacyChange = (e) => {
        setPrivacyChecked(e.target.checked);
    };
    return (

        <form className="row g-3 needs-validation" noValidate onSubmit={enviarCorreo}>
            <div className="col-12 col-md-6 mb-3">
                <label htmlFor="name" className="form-label">Nombre</label>
                <input type="text" className="form-control" id="name" placeholder="" value={name} onChange={e => setName(e.target.value)} required />
                {nameError && <span className="c-fa9529">* Campo obligatorio</span>}
            </div>
            <div className="col-12 col-md-6 mb-3">
                <label htmlFor="mail" className="form-label">Correo</label>
                <input type="email" className="form-control" id="mail" placeholder="" value={mail} onChange={e => setMail(e.target.value)} required />
                {mailError && <span className="c-fa9529">Indica un correo valido</span>}
            </div>
            <div className="col-12 mb-3">
                <label htmlFor="body" className="form-label">Mensaje</label>
                <textarea className="form-control" id="body" rows="3" value={body} onChange={e => setBody(e.target.value)} ></textarea>
                {bodyError && <span className="c-fa9529">*Campo obligatorio</span>}
            </div>
            <div className='col-12 text-start'>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" id="cbAvisoPrivacidad" checked={privacyChecked} onChange={handlePrivacyChange} />
                    <label htmlFor="cbAvisoPrivacidad" className="form-check-label fs-5">
                        Acepto que, he Leído y acepto el <a className='text-white' href="https://inspirum.blob.core.windows.net/legal/Bizlab_Aviso_de_Privacidad.pdf" target="_blank">Aviso de Privacidad</a>.
                    </label><br />
                    {privacyCheckedError && <span className="c-fa9529">*Para enviar el correo es necesario aceptar el aviso de privacidad.</span>}
                </div>
            </div>
            <div className="col-12">
                <button className="btn btn-primary btn--color rounded-pill ps-4 pe-4 text-white" type="submit">Enviar</button>
            </div>
        </form>
    )

}
export default ContactForm;