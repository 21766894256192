
import React, { Component } from 'react';
import img_6 from '../../Assets/Img/modules_img_6.jpeg';
function ModulesSection7() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="fade-right">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Centro de Entrenamiento</h2>
                            <p className="lead c-8b95a3 text-wrap">Donde los usuarios pueden tomar cursos, capacitarse y responder exámenes (tests), incrementando su conocimiento respecto a los proyectos en los que participan o los productos que comercializan.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img className="img-fluid" src={img_6} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection7;