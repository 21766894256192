
import React, { Component } from 'react';
import img_11 from '../../Assets/Img/modules_img_11.jpeg';
import IcoSvg from '../shared/svg/icoSvg';
function ModulesSection12() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img className="img-fluid" src={img_11} alt="" />

                    </div>
                    <div className="col-12 col-md-6" data-aos="fade-left">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Consola de Administración</h2>
                            <p className="lead c-8b95a3 text-wrap">Desde la consola de administración, cada administrador involucrado en el proyecto podrá realizar todas las acciones necesarias, de acuerdo a su nivel de jerarquía, para la gestió exitosa de cada proyecto. Algunas de las funciones principales son:</p>
                            <ul className="estio-lista c-8b95a3">
                                <li><IcoSvg /> Configuración y administración de reglas de participación</li>
                                <li><IcoSvg /> Cálculo automatizado de recompensas</li>
                                <li><IcoSvg /> Aprobación de recompensas</li>
                                <li><IcoSvg /> Gestión de medios (SMS, Mail, Notificaciones, Foro de discusión, Anuncios)</li>
                                <li><IcoSvg /> Seguimiento detallado de la actividad de los usuarios al interactuar con la plataforma</li>
                                <li><IcoSvg /> Administración de cursos y cuestionarios</li>
                                <li><IcoSvg /> Gestión de recompensas</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection12;