import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../Assets/Img/logo.png';

function Menu() {
    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
            <div className="container">


                <img onClick={() => navigate('/')} className="logo-img pointer" src={logo} alt="/" />

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" exact to="/">
                                Inicio
                            </NavLink>
                            <hr></hr>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active" to="/modules">
                                Módulos
                            </NavLink>
                            <hr></hr>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu;


