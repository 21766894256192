
import React, { Component } from 'react';
import img_9 from '../../Assets/Img/modules_img_9.jpeg';
function ModulesSection10() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000" >
                        <img className="img-fluid" src={img_9} alt="" />

                    </div>
                    <div className="col-12 col-md-6" data-aos="fade-left">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Tienda de Códigos Digitales</h2>
                            <p className="lead c-8b95a3 text-wrap">La plataforma ofrece un mecanismo rápido y seguro para que los usuarios obtengan recompensas por sus logros. Los usuarios tienen acceso a una tienda donde pueden intercambiar sus puntos obtenidos por códigos digitales, los cuales pueden ser redimidos de forma digital. Contamos con el catálogo más completo de certificados de regalo digitales.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection10;