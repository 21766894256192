import React, { useState, useEffect } from 'react';
import Menu from './shared/menu';

import '../Assets/Css/general.css';
import Footer from './shared/footer';
import ModulesSection1 from './modulesSection/section1';
import ModulesSection2 from './modulesSection/section2';
import ModulesSection3 from './modulesSection/section3';
import ModulesSection4 from './modulesSection/section4';
import ModulesSection5 from './modulesSection/section5';
import ModulesSection6 from './modulesSection/section6';
import ModulesSection7 from './modulesSection/section7';
import ModulesSection8 from './modulesSection/section8';
import ModulesSection9 from './modulesSection/section9';
import ModulesSection10 from './modulesSection/section10';
import ModulesSection11 from './modulesSection/section11';
import ModulesSection12 from './modulesSection/section12';
import HomeSection5 from './homeSection/section5';
import Loader from './shared/loader';

function Modules() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // Simula un tiempo de carga de 2 segundos
        setTimeout(() => {
            setIsLoading(false);
        }, 900);
    }, []);
    return (
        <div >
            {isLoading ? (
                <Loader></Loader>
            ) : (
                <div><Menu></Menu>
                    <ModulesSection1></ModulesSection1>
                    <ModulesSection2></ModulesSection2>
                    <ModulesSection3></ModulesSection3>
                    <ModulesSection4></ModulesSection4>
                    <ModulesSection5></ModulesSection5>
                    <ModulesSection6></ModulesSection6>
                    <ModulesSection7></ModulesSection7>
                    <ModulesSection8></ModulesSection8>
                    <ModulesSection9></ModulesSection9>
                    <ModulesSection10></ModulesSection10>
                    <ModulesSection11></ModulesSection11>
                    <ModulesSection12></ModulesSection12>
                    <HomeSection5></HomeSection5><Footer></Footer>
                </div>
            )}


        </div >

    )
}

export default Modules;