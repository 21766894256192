
import React, { Component } from 'react';
import img_7 from '../../Assets/Img/modules_img_7.jpeg';
function ModulesSection8() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img className="img-fluid" src={img_7} alt="" />
                    </div>
                    <div className="col-12 col-md-5" data-aos="fade-left">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Administración de Puntos</h2>
                            <p className="lead c-8b95a3 text-wrap">Los usuarios pueden ir consultando los puntos que han obtenido a través del cumplimiento de los objetivos definidos. Lo cual mantiene a los usuarios informados, enganchados y motivados en alcanzar sus metas.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection8;