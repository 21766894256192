
import React, { Component } from 'react';
import ContactForm from '../shared/contactForm';
function HomeSection5() {
    return (
        <section id="BarraContacto" className="section-wrap newsletter bg-img bg-overlay text-white relative pb-100 bottom-divider">
            <div className="container">
                <div className="row ">
                    <div className="col-12 col-md-8 offset-md-2 text-center">
                        <h2 className="newsletter__title">¿Listo para iniciar y comenzar a ver grandes resultados?</h2>
                        <p className="newsletter__subtitle" id="fix-margin_1">
                            Contáctanos, uno de nuestros expertos se pondrá en contacto para conocer a fondo las necesidades de su empresa, configurar la plataforma a la medida de sus necesidades y ofrecerles el plan más adecuado.
                        </p>

                    </div>
                    <div className='col-12 col-md-8 offset-md-2'>
                        <ContactForm></ContactForm>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection5;