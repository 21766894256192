import React, { Component } from 'react';
import img_0 from '../../Assets/Img/home_img_0.jpeg';
import img_1 from '../../Assets/Img/home_img_1.png';
function HomeSection1() {
    return (

        <section className="section-wrap hero bg-img white-text pb-100"
            style={{ backgroundImage: `url(${img_0})` }}>
            <div className="container  hero__container container-full-height">
                <div className="row hero__outer align-items-center">
                    <div className="col-12 col-lg-5" data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1500">
                        <h1 className="text-white text-md-end">La plataforma más avanzada para la gestión de incentivos y
                            administración de desempeño.</h1>
                        <div className="hero__btn-holder mt-30">
                            <a href="#BarraContacto" className="btn btn--lg btn-info btn--color rounded-pill ps-4 pe-4 text-white">
                                Contacto
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7" data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-duration="2000">
                        <img src={img_1} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeSection1;