
import React, { Component } from 'react';
import img_4 from '../../Assets/Img/modules_img_4.jpeg';
function ModulesSection5() {
    return (
        <section className="section-wrap promo-section bottom-divider pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-6 mb-5" data-aos="fade-right">
                        <div className="promo-section__description">
                            <h2 className="promo-section__title">Bandeja de Entrada</h2>
                            <p className="lead c-8b95a3 text-wrap">Cada usuario cuenta con una bandeja donde puede recibir correos enviados directamente desde la plataforma o desde el exterior.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000">
                        <img className="img-fluid" src={img_4} alt="" />

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ModulesSection5;